*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.nuevoequipo {
    justify-content: center;
    background-image: url("Imagenes/mina2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.nuevoequipo-body {
    height: 92vh;
    width: 100%;
    display: grid;
    justify-content: center;
}

.boton{
    width: 100Px;
    height: 40Px;
    background-color: white;
    display: flex;
    align-items: center;
    place-content: center;
    border-radius: 30px;
    border: 2px solid black;
    cursor: pointer;
    font-size: 14px;
}

.boton:hover, .boton:focus {
    box-shadow: 0 0 10px 1px #000000;
}

.formularionuevoequipo {
    justify-content: center;
    width: 550px;
    height: 50vh;
    display: grid;
    position: relative;
    top: 10%;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    outline: solid 4px #000000;
}

.formularionuevoequipo-1{
    position: relative;
    height: 35vh;
    width: 50vh;
    align-items: center;
    justify-content: center;
    display: grid;
}

.formularionuevoequipo-2{
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    bottom: 0;
    height: 10vh;
    width: 50vh;
    gap: 20px;
}

#estilo{
    position: relative;
    left: 0%;
    color: black;
    height: 25px;
    width: 200px;
    border-radius: 5px;
    white-space: nowrap;
}

.ordenar{
    align-items: center;
    justify-content: space-between;
    display: flex;
    height: 5vh;
    width: 500px;
    white-space: nowrap;
    font-size: 14px;
    font-weight: bold
}