*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }


.global{ 
  justify-content: center;

}

.globaldiv{
  display: flex;
  width: 14%;
  color: black;
  justify-content: space-around ;
  align-items: center;  
  font-size: 1.5vh;
  white-space: nowrap;
}

@media (max-width: 800px){
  .globaldiv{
  
          scale: 0.5; 
          width: 15%;
          text-align: center;

      }
  }
  

.cuerporiego{
    height: 100%;
    width: 100%;
    background-color: gray;
}

.Contenedorriego{
    background-image: url("Imagenes/Mina.jpg");
    background-size: cover;
    background-repeat: repeat;
    background-attachment: fixed;
    background-position: top 110px left 40px;
    min-height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 83vh;
  }
  
  .Principal{
    width: 80%;
    margin-left: 3%;

  }
  
@media (max-width: 1200px){
  .Principal{    
          flex-direction: column;
          align-items: center;
          height: 80%;
          margin-left: 10%;
          margin-top: -10%;
  
  }
  .p1{    
    margin-top: 0%;
    flex-direction: column;
    align-items: center;
    width: 100%;
    scale: 0.8;
    height: 50%;

  }
}

.p11, .p12{
  height: 50% ;
  width: 100%;
  display: flex;
  margin-top: 3%;
  align-items: center;
}

@media (max-width: 800px){
  .Principal{    
    align-items: center;
    flex-direction: column-reverse;
    height: 80%;
    width: 90%;
    margin-left: 5%;
    margin-top: -25%;
  }
  .p1{    
    margin-top: 0%;
    width: 140%;
    margin-left: -20%;
    scale: 0.7;
  }
  .p11  { 
  scale: 0.9;
  }
 .p12  { 
  margin-top: 10%;
  scale: 0.9;
  }
}

  
.p1{
    height: 50% ;
    display: flex;
    align-items: center;
    place-content: center;
    margin-top: 5%;
}

  
.p2{
    margin-top: -10%;
    height: 50% ;
    display: flex;
    align-items: center;
    place-content: center;
    
}

@media (max-width: 1200px){
  .p2{    
          margin-top: 0%;
          flex-direction: column;
          align-items: center;
          width: 100%;
          scale: 0.8;
          height: 50%;
  
      }
  }
  
  @media (max-width: 800px){
    .p2{    
    margin-top: 0%;
    width: 140%;
    margin-left: -20%;
    scale: 0.7;
        }
    }
  
.p21, .p22{
    height: 50% ;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 3%;
}

@media (max-width: 800px){
  .p21  { 
scale: 0.9;
      }
  }

@media (max-width: 800px){
  .p22  { 
margin-top: 10%;
scale: 0.9;
      }
  }
  
.Sidebar{
    width: 10%;
    background-color: rgb(255, 255, 255, 0.9);
    font-weight: bold;
    display: grid;
  }
  
  @media (max-width: 1200px){
    .Sidebar{
            display: block;
            width: 100%;
            height: 10%;

    }
}

  
.s1, .s2{
    position: relative;
    top: 10%;
    width: 100%;
    height: 80%;
    display: grid;
    place-items: center;
}

@media (max-width: 1200px){
  .s1{
    display: flex;
    height: 60%;
  }
}


@media (max-width: 800px){
  .s2, .tanquearreglo{
    scale: 0.7
  }
}


.semaforo{
  width: 60px;
  height: 100px;
  margin: auto;
  position: relative;
  text-align: center;
}


.semaforodos{
  width: 60px;
  height: 100px;
  margin: auto;
  position: relative;
  text-align: center;
  align-content: flex-end;
}

.regadortag{
  position: relative;
  color: black;
  font-weight: bold;
  height: 25px;
}

.regadortag2{
  position: relative;
  color: black;
  font-weight: bold;
  height: 25px;
}

.footerriego{
    background-color: rgba(0, 0, 0, 0.8);
    height: 3vh;
    color: rgb(255, 255, 255);
    justify-content: space-around;
    width: 100%;
    display: flex;
    overflow: hidden;
    gap: 10px;
}

.reajustefooter{
  height: 100%;
  width: 100%;
  justify-content: space-around;
  display: flex;
}

@media (max-width: 1300px){
  .reajustefooter{
    scale: 0.9;
    white-space: nowrap;
    justify-content: space-around;
    gap: 10px;
  }
}

@media (max-width: 1200px){
  .reajustefooter{
    scale: 0.8;
    white-space: nowrap;
    justify-content: space-around;
    gap: 10px;
  }
}

@media (max-width: 800px){
  .footerriego{
    height: 32vh;
  }
  .reajustefooter{
    margin-top: -5%;
    scale: 0.8;
    display: grid;
    justify-content: space-evenly;
    text-align: center;
  }
}


#cerrar{
  position: absolute;
  right: 5px;
  cursor: pointer;
  top: 18%;
}

.panicoon{
  position: absolute;
  right: 5px;
  cursor: pointer;
  bottom: 1%;
  width: 200px;
  height: 25px;
  border-radius: 40px;
  background-color: #00ff6a;
  box-shadow: 0 0 10px 1px #2ecc71;
  border: solid 3px black;
  text-align: center;
  color: black;
}

#reajuste{
  position: relative;
  top: -20%;
}

.panicooff{
  position: absolute;
  right: 5px;
  cursor: pointer;
  bottom: 1%;
  width: 200px;
  height: 25px;
  border-radius: 40px;
  background-color: #fc1900;
  box-shadow: 0 0 10px 1px #c0392b;
  border: solid 3px black;
  text-align: center;
  color: white;
}

@media (max-width: 1200px){
  #cerrar{
    scale: 0.8;
    right: 1px;
    top: 28%;
  }
  .panicooff, .panicoon{
    scale: 0.8;
    top: 94%;
    right: -10px;
  }
}


@media (max-width: 800px){
  #cerrar{
  display: none;
  }
  .panicooff, .panicoon{
    scale: 0.8;
    top: 125vh
  }
}

#sidebar {
  display: none;
}

.tanque{
  position: relative;
  display: flex;
  gap: 5%;
  margin-bottom: -20%;
}

.tanque-de-agua {
  position: relative;
  width: 100px;
  height: 150px;
  border: 1px solid black;
  overflow: hidden;
  border-radius: 10px;
}

.agua {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 255, 0.7);
}

.linea-nominal {
  position: absolute;
  top: 25%;
  width: 100%;
  height: 2px;
  background-color: orange;
}

.linea-critica {
  position: absolute;
  top: 15%;
  width: 100%;
  height: 2px;
  background-color: red;
}

.tanquearreglo{
  position: relative;
  top: 10%;
  width: 100%;
  height: 80%;
  display: none;
  place-items: center;
}

@media (max-width: 1200px){
  .tanque-de-agua{
    display: none;
  }
  .tanquearreglo{
    display: flex;
  }

}

#archivos{
  text-align: center;
  display: none;
  position: absolute;
  top: 8vh;
  height: 92vh;
  width: 100%;
  background-color: rgb(255, 255, 255, 0.95);
  z-index: 2;
  padding: 5px;
  max-height: 92vh; /* Altura máxima del contenedor */
  overflow-y: auto; /* Agregar scroll vertical */
}

@media (max-width: 1025px){

  .semaforo{    
    scale: 0.85
  }
  .semaforodos{    
    scale: 0.85
  }

}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.Riego {
  width: 100vw;
  height: 100vh;
  background-color: rgb(185, 185, 185);
  display: grid;
  justify-items: center;
  overflow-x: hidden;
}

.Riegotop{
  width: 98vw;
  height: 15vh;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  margin: 1%;
  gap: 10px;
}

@media (max-width: 1500px){
  .Riegotop{
      transform: scale(0.95);
      height: auto;
      justify-content: center;
  }
}
@media (max-width: 1400px){
  .Riegotop{
      transform: scale(0.9);
      height: auto;
      justify-content: center;
  }
}
@media (max-width: 1300px){
  .Riegotop{
      transform: scale(0.85);
      height: auto;
      justify-content: center;
  }
}

@media (max-width: 1200px){
  .Riegotop{
      transform: scale(0.8);
      height: auto;
  }
}

@media (max-width: 1100px){
  .Riegotop{
      transform: scale(0.7);
      height: auto;
  }
}
@media (max-width: 1024px){
  .Riegotop{
      transform: scale(0.6);
      height: auto;
  }
}
@media (max-width: 900px){
  .Riegotop{
      transform: scale(0.5);
      height: auto;
  }
}

@media (max-width: 800px){
  .Riegotop{
      transform: none;
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 100vw;
      height: auto;
      margin: 0%;
      gap: 0px;
  }
}


.Riegotopitem{

  outline: 2px solid rgb(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  width: 280px ;
  height: 14vh;
  align-items: center;
  position: relative;
  justify-content: center;
  background-color: rgb(218, 218, 218);
  box-shadow: 5px 5px 3px black;
}


.Riegotopitemcel{
  
  outline: 2px solid rgb(0, 0, 0, 0.5);
  border-radius: 10px;
  display: none;
  width: 280px ;
  height: 14vh;
  align-items: center;
  position: relative;
  justify-content: center;
  background-color: rgb(218, 218, 218);
  box-shadow: 5px 5px 3px black;
}

@media (max-width: 800px){
  .Riegotopitem{
    transform: scale(0.8);
    
  }
}
@media (max-width: 800px){
  .Riegotopitemcel{
    transform: scale(0.8);
    display: flex
  }
  }

.Riegomid{

  width: 100vw;
  height: 65vh;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

@media (max-width: 1200px){
  .Riegomid{
      transform: scale(0.95);
  }
}

@media (max-width: 800px){
.Riegomid{
  display: grid;
  transform: scale(0.96);
  height: auto;
  position: relative;
  justify-items: center;
}
}

.Riegomidside{

  width: 12vw;
  height: 65vh;
  display: grid;
  position: relative;
  justify-content: center;
  align-items: center;
  outline: 2px solid rgb(0, 0, 0, 0.5);
  border-radius: 10px;
  background-color: rgb(218, 218, 218);
  box-shadow: 5px 5px 3px black;
  white-space: wrap;

}

@media (max-width: 800px){
  .Riegomidside{
      
      transform: scale(0.9);
      display: flex;
      width: 100vw;
      height: 10vh;
      margin-bottom: 5%
  }
  }
  

.Riegomidbody{
  
  width: 86vw;
  height: 65vh;
  display: grid;
  position: relative;
  
}

.Riegomidbodysem{
  position: relative;
  display: flex;
  height: 59vh;
  width: 85vw;
  background-image: url("Imagenes/Mina3.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  outline: 2px solid rgb(0, 0, 0, 0.5);
  border-radius: 10px;
  justify-content: center;
  background-color: rgb(218, 218, 218);
  box-shadow: 5px 5px 3px black;
  margin-left: 1vh;
}

@media (max-width: 1024px){
  .Riegomidbodysem{
    background-position: 35% top;
    margin-left: 0vh;
  }
}

.Riegomidbodyley{
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  white-space: nowrap;
  width: 85vw;
  height: 5vh;
  outline: 2px solid rgb(0, 0, 0, 0.5);
  border-radius: 10px;
  background-color: rgb(218, 218, 218);
  box-shadow: 5px 5px 3px black;
  margin-left: 1vh;
  margin-top: 1vh
}

@media (max-width: 1024px){
  .Riegomidbodyley{
      font-size: 10px;
      margin-left: 0vh;
  }
}

@media (max-width: 1024px){
  .Riegomidbodyley{
      font-size: 8px;
      white-space: wrap;
  }
}

.Riegobot {
  width: 20vw;
  height: 3vh;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 50px 50px 0 0; /* Aplicar solo en la parte superior */
  bottom: 0;
  background-color: #0066cc;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 1024px){
  .Riegobot{
    width: 40vw;
  }
}

.Riegomidtop {
  width: 80vw;
  height: 10vh;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  top: 22%
}

.Riegomidbot {
  width: 80vw;
  height: 10vh;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  top: 62%
}

@media (max-width: 1024px){
  .Riegomidtop{
      transform: scale(0.85);
      top: 26%
  }
  .Riegomidbot{
      transform: scale(0.85);
      top: 55%
  }
  .Riegobot{
      font-size: 12px;
  }
}

@media (max-width: 800px){
  .Riegomidtop{
      transform: scale(0.65);
      top: 20%
  }
  .Riegomidbot{
      transform: scale(0.65);
      top: 55%
  }
  .Riegobot{
      font-size: 8px;
  }
}
@media (max-width: 600px){
  .Riegomidtop{
      transform: scale(0.40);
      top: 30%
  }
  .Riegomidbot{
      transform: scale(0.40);
      top: 50%;
      left: 10%
  }
  .Riegobot{
      font-size: 8px;
  }
}

#listadohistorico {
  display: none;
  position: absolute;
  bottom: -650px;
  height: 650px;
  width: 100vw;
  background-color: rgb(255, 255, 255, 0.98);
  justify-content: center;
  transition: bottom 0.5s; /* Transición al cambiar la propiedad bottom */
  max-height: 650px; /* Altura máxima del contenedor */
  overflow-y: auto; /* Agregar scroll vertical */
  overflow-x: hidden; /* Ocultar scroll horizontal */
  z-index: 3;
}

.tank2{
  display: none;
}
.tank3{
  display: none;
}

@media (max-width: 1100px){
  .tank1{
      display: none;
  }
  .tank2{
      display: flex;
  }
  }
  
@media (max-width: 800px){
  .tank1{
      display: none;
  }
  .tank2{
      display: none;
  }
  .tank3{
      display: flex;
  }

  }
  
.leyendatop{
  height: 20vh;
  width: 10vw;
  display: grid;
  text-align: center;
  justify-content: center;
  align-items: center; 
  font-size: 16px;
}

@media (max-width: 800px){
  .leyendatop{
    height: 10vh;
    width: 20vh;
    display: flex;
    font-size: 12px;
    transform: scale(0.6);
    gap: 5px
  }
}

.leyendamid{
  height: 20vh;
  width: 10vw;
  display: grid;
  text-align: center;
  justify-content: center;
  align-items: center; 
  font-size: 16px;
}

@media (max-width: 800px){
  .leyendamid{
    height: 10vh;
    width: 10vh;
  }
}
.leyendabot{
  height: 20vh;
  width: 10vw;
  display: grid;
  text-align: center;
  justify-content: center;
  align-items: center; 
  font-size: 16px;
}

@media (max-width: 800px){
  .leyendabot{
    height: 10vh;
    width: 20vh;
    display: flex;
    font-size: 12px;
    transform: scale(0.6);
    gap: 10px
  }
}

.leyendabold{
  font-weight: bold;
  margin-top: -10px;
}

@media (max-width: 800px){
  .leyendabold{
    margin-top: 0px;
  }
}

.liststyle{
  width: 80vw; 
  margin-top: 1vh
}

.imagenAmpliada {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Fondo oscuro semi-transparente */
  display: grid;
  justify-content: center;
  align-items: center;
  z-index: 10; /* Poner encima de todo */
  cursor: pointer;
  text-align: center;
}

.imagenAmpliada img {
  max-width: 100%;
  max-height: 100%;
}