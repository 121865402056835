.sidebar {
    width: 15%;
    height: 92vh;
    background: #f1f1f1;
    position: fixed;
    top: 8%;
    left: 0px;
    z-index: 5;
    border-radius: 10px;
  }
  
.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  cursor: default;
}

.sidebar li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.sidebar-item{
  gap: 5%;
  display: flex;
  align-items: center;
  place-items: center;
}

.sidebar-lastitem{
  gap: 5%;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0%;
  align-items: center;
  place-items: center;
}

#newuser {

    display: none;
    
}

#bar {
  display: none;
}


@media (max-width: 800px){
  
  .sidebar{
    width: 50%;
    height: 78%;
  }
}

#submenu {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  width: 180px;
  background: #f1f1f1;
  border: 1px solid #ccc;
  z-index: 1;
  cursor: pointer;
}

.sidebar-item:hover #submenu {
  display: block;
}

#menusidebarnew{
  position: absolute;
  left: 1%;
  top: 1.5%;
  cursor: pointer;
  z-index: 1;
  display: flex;
}