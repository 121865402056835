

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: 0.5s;
}

.bodyprueba {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.bodyprueba::before {
    content: "";
    background-image: url(Imagenes/mina2.jpg);
    position: absolute;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    filter: brightness(0.25);
    z-index: -1;
}

.mainprueba {
    width: 600px;
    height: 500px;
    display: flex;
    gap: 15px;
}

.card {
    width: 20%;
    border-radius: 15px;
    border: transparent;
    cursor: pointer;
    overflow: hidden;
    color: white;
}
.card:hover {
    width: 80%;
}

.card .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card:hover .image {
    transform: scale(1.2);
}

.card .background {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    pointer-events: none;
    filter: brightness(0.25);
    opacity: 0;
}
.card:hover .background {
    opacity: 1;
}

.card .layer {
    width: 100%;
    height: 50%;
    margin-top: -250px;
    position: relative;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent);
}

.card .layer .info {
    width: 260px;
    position: absolute;
    bottom: 10px;
    padding: 20px;
    opacity: 0.8;
    writing-mode: vertical-rl;
    transform: scale(-1, -1);
    text-orientation: mixed;
    text-wrap: nowrap;
}

.card .layer .info2 {
    width: 260px;
    position: absolute;
    bottom: 10px;
    padding: 20px;
    opacity: 0;
}

.card:hover .layer .info {
    opacity: 0;
}

.card:hover .layer .info2 {
    opacity: 1; 
}

.info h1 {
    color: rgba(255, 255, 255, 0.75);
    font-size: 20px;
}
.info2 h1 {
    color: rgba(255, 255, 255, 0.75);
}

#menusidebar{
    position: absolute;
    left: 1%;
    top: 3%;
    cursor: pointer;
    z-index: 1;
    display: flex;
}

#firstlogin {
    display: none;
}

#pc{
    display: flex;
}
#mv{
    display: none;
}

@media (max-width: 800px){

    .mainprueba{    
        width: 400px;
        height: 300px;
        display: flex;
        gap: 15px;
        transform: rotate(90deg);
    }

    .mainprueba img{
        transform: rotate(-90deg);
    }

    .card:hover .image {
        transform: scale(1.2);
        transform: rotate(-90deg);
    }

    .card .layer {
        width: 100%;
        height: 84%;
        margin-top: -250px;
        position: relative;
        background: linear-gradient(to top, rgba(0, 0, 0, 0.2s), transparent);
    }

    .card .image {
        display: flex;
        width: 300px;
        height: 100%;
        object-fit: cover;
    }

    .info h1 {
        color: rgba(255, 255, 255, 0.75);
        font-size: 12px;
    }

    .info2 h1 {
        color: rgba(255, 255, 255, 0.75);
    }

    .card .layer .info {
        width: 260px;
        position: absolute;
        bottom: 10px;
        padding: 20px;
        opacity: 0.8;
        writing-mode: vertical-rl;
        transform: scale(-1, -1);
        text-orientation: mixed;
        text-wrap: nowrap;
    }
    
    .card .layer .info2 {
        width: 260px;
        position: absolute;
        bottom: 10px;
        padding: 20px;
        opacity: 0;
    }

    .card:hover .layer .info {
        opacity: 1;
    }
    
    .card:hover .layer .info2 {
        opacity: 0; 
    }

    #pc{
        display: none;

    }
    #mv{
        display: flex;
    }
}

#firstlogin {
    display: none;
}