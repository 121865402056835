*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.listado {
    justify-content: center;
    background-image: url("Imagenes/mina2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.listado-body {
    height: 92vh;
    width: 100%;
    display: grid;
    justify-content: center;
}

.boton{
    width: 100Px;
    height: 40Px;
    background-color: white;
    display: flex;
    align-items: center;
    place-content: center;
    border-radius: 30px;
    border: 2px solid black;
    cursor: pointer;
    font-size: 14px;
}

.boton:hover, .boton:focus {
    box-shadow: 0 0 10px 1px #000000;
}

.listadocontainer {
    justify-content: center;
    width: 100vw;
    height: 92vh;
    display: grid;
    position: relative;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 2%;
    text-align: center;
    max-height: 92vh; /* Altura máxima del contenedor */
    overflow-y: auto; /* Agregar scroll vertical */
}

.updateequipos{
    justify-content: center;
    width: 50vw;
    height: 50vh;
    display: none;
    position: relative;
    padding: 2%;
    text-align: center;
}

.updatechips{
    justify-content: center;
    width: 50vw;
    height: 50vh;
    display: none;
    position: relative;
    padding: 2%;
    text-align: center;
}

.table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th{
    padding: 8px;
  }

 .table td {
    padding: 3px 30px
  }

  
  @media (max-width: 800px){
    .container{
        transform: scale(0.6);
        justify-content: center;
        display: grid;
    }
  }