.primerloginbody{
    position: absolute;
    background-image: url('../Imagenes/mina2.jpg');
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    display: flex;
    top: 0;
    left: 0;
}

.primerlogin{
    height: 80%;
    width: 65vh;
    display: grid;
    position: relative;
    justify-content: center;
    justify-items: center;
    border-radius: 10px;
    outline: solid 4px #000000;
    padding: 0 2% 0% 2%;
    background: rgba(255, 255, 255, 0.9);
}

.primerlogin1-1{
    position: relative;
    height: 60vh;
    width: 60vh;
    display: grid;
    align-items: center;
    justify-content: center; /* Centrar horizontalmente */
    text-align: center;
}

.botonprimerlogin{
    width: 100Px;
    height: 40Px;
    background-color: white;
    display: flex;
    align-items: center;
    place-content: center;
    border-radius: 30px;
    border: 2px solid black;
    cursor: pointer;
    font-size: 14px;
}

.botonprimerlogin:hover, .botonprimerlogin:focus {
    box-shadow: 0 0 10px 1px #2ecc71;
}


.primerlogin1-2{
    align-items: center;
    justify-content: center;
    display: flex;
    height: 10vh;
    width: 50vh;
    gap: 20px;
}

.primerloginordenar{
    align-items: center;
    justify-content: space-between;
    display: flex;
    height: 5vh;
    width: 60vh;
    white-space: nowrap;
    font-size: 1.5vh;
    font-weight: bold;
}

#primerloginestilo{
    position: relative;
    color: green;
    height: 30px;
    width: 200px;
    border-radius: 5px;
    white-space: nowrap;
    font-size: 14px;
    font-weight: bold;
}

@media (max-width: 800px){
    .primerlogin{
        width: 48vh;
    }
    .primerloginordenar{
        display: grid;
        width: 35vh;
        height: 10vh;
        align-items: center;
        justify-content: center;
    }
    .primerlogin1-1{
        width: 0vh;
    }
    #primerloginestilo{
        position: relative;
        justify-content: center;
    }
  }

  .ojo {
    height: 30px; 
    width: 30px;
    cursor: pointer; 
    position:absolute;
    right: 0px;
  }

  @media (max-width: 800px){
    .ojo {
        height: 30px; 
        width: 30px;
        cursor: pointer; 
        position:relative;
        left: -35px;
      }
  }