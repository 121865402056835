.ventanaformularionu{
    background: rgba(255, 255, 255, 0.9);
    height: 100vh;
    width: 100vw;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center; /* Alinear al centro verticalmente */
    text-align: center; /* Alinear al centro horizontalmente */
    top: -8vh;
    z-index: 8;
}

.formularionu{
    height: 80%;
    display: grid;
    justify-content: center;
    align-items: center; /* Alinear al centro verticalmente */
    text-align: center; /* Alinear al centro horizontalmente */
    position: relative;
    top: 0%;
    padding: 0 2% 0% 2%;
    border-radius: 10px;
    background-color: rgba(146, 146, 146, 0);
    outline: solid 4px #000000;
    backdrop-filter: blur(15px);
}

.formulario1nu{
    position: relative;
    height: 60vh;
    width: 55vh;
    align-items: center;
    justify-content: center;
    display: grid;
    margin: 0 auto; /* Centrar horizontalmente */
}

@media (max-width: 800px){
    .formularionu{
        width: 96%;
    }
    .formulario1nu{
        position: relative;
        height: 60vh;
        width: 40vh;
    }
  }

.boton{
    width: 100Px;
    height: 40Px;
    background-color: white;
    display: flex;
    align-items: center;
    place-content: center;
    border-radius: 30px;
    border: 2px solid black;
    cursor: pointer;
    font-size: 14px;
}

.boton:hover, .boton:focus {
    box-shadow: 0 0 10px 1px #000000;
}

.formulario2nu{
    align-items: center;
    justify-content: center;
    display: flex;
    height: 10vh;
    width: 500px;
    gap: 20px;
}

.ordenarnu{
    align-items: center;
    justify-content: space-between;
    display: flex;
    height: 5vh;
    width: 45vh;
    white-space: nowrap;
    font-size: 14px;
    font-weight: bold;
}

.estilo{
    position: relative;
    left: 0%;
    color: rgb(0, 0, 0);
    height: 30px;
    width: 200px;
    border-radius: 5px;
    white-space: nowrap;
    font-size: 14px;
}

.cerrar{
    position: absolute;
    right: 0.5%;
    top: 1%;
    cursor: pointer
}

.ojonu {
    height: 30px; 
    width: 30px;
    cursor: pointer; 
    position:absolute;
    right: 50px;
  }

@media (max-width: 800px){
    .cerrar{
        display:none
    }
    .ordenarnu{
        align-items: center;
        justify-content: center;
        display: grid;
        height: 8vh;
        width: 30vh;
        white-space: nowrap;
        font-size: 14px;
        font-weight: bold;
        place-content: center;
    }
    .ojonu {
        height: 30px; 
        width: 30px;
        cursor: pointer; 
        position:absolute;
        right: 45px;
      }
  }