*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.cuerpoimagenes{
    background-image: url("Imagenes/mina2.jpg");
    background-size: cover;
    background-repeat: repeat;
    background-attachment: fixed;
    height: 100vh;
    width: 100vw;
    display: grid;
    max-height: 100vh; /* Altura máxima del contenedor */
    overflow-y: auto; /* Agregar scroll vertical */
    overflow-x: hidden; /* Ocultar scroll horizontal */
}

.q4x5grid{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 86vh;
    width: 100vw;
}

.q4x5{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100vw;
    height: 86vh;
    max-height: 86vh; /* Altura máxima del contenedor */
    overflow-y: auto; /* Agregar scroll vertical */
    overflow-x: hidden; /* Ocultar scroll horizontal */
}

.item{
    margin: 5px;
    height: 220px;
    width: 270px;
    outline: 1px solid black;
    background-color: rgb(255, 255, 255, 0.8);
    position: relative;
    overflow: hidden;
    display: grid;
}

.item img{
    height: 160px;
    width: 270px;
    padding: 5px;
    cursor: pointer;
}

.item h1{
    justify-content: space-between;
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
}

.imagenAmpliada {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Fondo oscuro semi-transparente */
    display: grid;
    justify-content: center;
    align-items: center;
    z-index: 10; /* Poner encima de todo */
    cursor: pointer;
    text-align: center;
  }
  
  .imagenAmpliada img {
    max-width: 100%;
    max-height: 100%;
  }

  .imagenAmpliada h1 {
    position: absolute;
    bottom: 100px;
    display: flex;
    color: white;
    font-size: 14px;
    left: 50%; /* Centrar horizontalmente */
    transform: translateX(-50%); /* Centrar horizontalmente */
}

.filtrotablet{
    position: relative;
    display: flex;
    top: 0;
    background-color: black;
    color: gray;
    height: 5vh;
    width: 100%;
    text-align: center;
    align-items: center;
    place-content: center;
    z-index: 4;
}

.filtrotablet h1{
    font-size: 2vh;
    margin: 10px;
    cursor: pointer;
}

@media (max-width: 800px){
    .filtrotablet h1{
        font-size: 1vh
    }
  }

.filtrotablet h1:hover{
    margin: 10px;
    color: rgba(255, 0, 0, 0.7);
}

.redLine {
    text-decoration: underline;
    text-decoration-color: red;
    transition: width 2s, transform 2s;
    transform-origin: left;
    color: white;
}

#cerrartablet{
    position: absolute;
    right: 5px;
    cursor: pointer;
    top: 14%;
    z-index: 1;
}

@media (max-width: 768px){
    #cerrartablet{
        display: none
    }
  }