*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    transition: 0.5s;
}

.cabina-header {
    justify-content: center;
    height: 12vh;
    width: 100%;
    display: flex;
    background-color: rgba(0, 0, 0, 0.6);
    box-shadow: 1px 3px 10px 1px rgba(15, 15, 15, 1);
    padding: 10px;
}

.cabina {
    justify-content: center;
    background-image: url("Imagenes/mina2.jpg");
}

.cabina-body {
    height: 92vh;
    width: 100%;
    display: grid;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    max-height: 92vh; /* Altura máxima del contenedor */
    overflow-y: auto; /* Agregar scroll vertical */
    overflow-x: hidden; /* Ocultar scroll horizontal */
}

.division1 {
    justify-content: center;
    width: 98vw;
    height: 5vh;
    display: flex;
    position: relative;
    top: 12%;
    border-radius: 10px;
    background-color: rgba(146, 146, 146, 0);
    gap: 2vw;
}


.division1-1{
    justify-content: center;
    align-items: center;
    width: 48vw;
    height: 5vh;
    display: flex;
    border-radius: 10px;
    outline: solid 2px rgb(0, 0, 0);
    backdrop-filter: blur(10px);
    gap: 30px;
    font-size: 1.1vw;
}

.division1-2{
    justify-content: center;
    align-items: center;
    width: 48vw;
    height: 5vh;
    display: flex;
    border-radius: 10px;
    gap: 10px;
}


.division1-2-1{
    justify-content: center;
    align-items: center;
    width: 22vw;
    height: 5vh;
    display: flex;
    border-radius: 10px;
    outline: solid 2px rgb(0, 0, 0);
    backdrop-filter: blur(10px);
}

.division1-2-1-2{
    justify-content: center;
    align-items: center;
    width: 22vw;
    height: 5vh;
    display: flex;
    border-radius: 10px;
    outline: solid 3px rgb(255, 0, 0);
    backdrop-filter: blur(10px);
}

.division1-2-2 {
    justify-content: center;
    align-items: center;
    width: 22vw;
    height: 5vh;
    display: flex;
    border-radius: 10px;
    outline: solid 2px rgb(0, 0, 0);
    backdrop-filter: blur(10px);
    gap: 10px;
    font-weight: bold;
    font-size: 1.1vw;
}

.division1-2-3 {
    justify-content: center;
    align-items: center;
    width: 3vw;
    height: 5vh;
    display: flex;
    cursor: pointer
}

@media (max-width: 800px){
    .division1 {
        display: grid;
        height: 0vh;
    }
    .division1-1{
        width: 98vw;
        font-size: 1.4vh;
    }
    .division1-2{
        width: 98vw;
        font-size: 1.4vh;
    }
    .division1-2-1{
        width: 49vw;
        font-size: 1.4vh;
    }
    .division1-2-1-2{
        width: 49vw;
        font-size: 1.4vh;
    }
    .division1-2-2{
        width: 49vw;
        font-size: 1.2vh;
    }
    .division1-2-3 {
        display: none
    }
    .cabina-body {
        height: 115vh;
        max-height: 115vh; /* Altura máxima del contenedor */
        overflow-y: auto; /* Agregar scroll vertical */
        overflow-x: hidden; /* Ocultar scroll horizontal */
    }
    
  }


.division2 {
    justify-content: center;
    width: 98vw;
    height: 20vh;
    display: flex;
    position: relative;
    top: 2%;
    border-radius: 10px;
    background-color: rgba(146, 146, 146, 0);
    gap: 2vw;
}

.division2-1 {
    justify-content: center;
    align-items: center;
    width: 48vw;
    height: 20vh;
    display: grid;
    border-radius: 10px;
    background-color: rgba(146, 146, 146, 0);
    outline: solid 2px #000000;
    backdrop-filter: blur(10px);
}

.division2-2 {
    justify-content: center;
    align-items: center;
    width: 48vw;
    height: 20vh;
    display: flex;
    border-radius: 10px;
    background-color: rgba(146, 146, 146, 0);
    outline: solid 2px #000000;
    backdrop-filter: blur(10px);
}

@media (max-width: 800px){
    .division2 {
        display: grid;
    }
    .division2-1{
        width: 98vw;
        height: 14vh;
    }
    .division2-2{
        width: 98vw;
        height: 14vh;
    }
  }

/* top | right | bottom | left */
.division3 {
    justify-content: center;
    align-items: center;
    width: 98vw;
    height: 60vh;
    display: grid;
    position: relative;
    border-radius: 10px;
    background-color: rgba(244,222,208,0.9);
    outline: solid 2px #000000;
    backdrop-filter: blur(10px);
    padding: 10px 5px 10px 5px;
}

.division3-0 {
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 5vh;
    display: flex;
    position: relative;
    border-radius: 10px;
    background-color: rgba(146, 146, 146, 0);
    padding: 10px 5px 10px 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    gap: 5px;
}

.division3-0 select {
    background-color: white;
    outline: 1px solid black;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-align: center;
    cursor: pointer;
}

.division3-1 {
    justify-content: center;
    align-items: center;
    width: 95vw;
    height: 50vh;
    display: flex;
    position: relative;
    border-radius: 10px;
    background-color: rgba(146, 146, 146, 0);
    padding: 10px 5px 10px 5px;
}

.estilotitulo1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 49vw;
    height: 5vh;
    font-size: 1vw;
    font-weight: bold;
}

.boxes {
    justify-content: center;
    align-items: center;
    width: 49vw;
    height: 15vh;
    display: flex;
    gap: 10%;
}

.estilotitulo2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10vw;
    height: 3vh;
    font-size: 1vw;
    font-weight: bold;
    background-color: white;
    border-radius: 5px;
}

.estilotitulo2-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5vw;
    height: 3vh;
    font-size: 0.7vw;
    font-weight: bold;
    background-color: white;
    border-radius: 5px;
}

.box1 {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 10vh;
    width: 10vw;
    gap: 5px;
}


.box2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7vh;
    width: 10vw;
    border-radius: 5px;
    font-size: 0.9vw;
    font-weight: bold;
    background-color: green;
}


.boxes2 {
    justify-content: center;
    align-items: center;
    width: 49vw;
    height: 20vh;
    display: flex;
    gap: 3%;
}

.box3 {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 18vh;
    width: 10vw;
    gap: 0px;
}

.box3-1 {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 18vh;
    width: 4vw;
    gap: 0px;
}

.estilotitulo3 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10vw;
    height: 3vh;
    font-size: 1vw;
    font-weight: bold;
    background-color: white;
    border-radius: 5px;
}

.menu {
    position: relative;
    justify-content: center;
}

.menu select {
    background-color: transparent;
    color: rgb(0, 0, 0);
    padding: 10px;
    width: 22vw;
    border: none;
    font-size: 1.1vw;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-align: center;
    cursor: pointer;
}

.menu select option, .bos2 select option{
    padding: 30px;
    justify-content: center;
    text-align: center;
}

@media (max-width: 800px){
    .menu select {
        font-size: 1.4vh;
        width: 49vw;
    }
    .boxes2 {
        width: 98vw;
        height: 10vh;
    }
    .box3 {
        height: 10vh;
        width: 20vw;
    }
    .box3-1 {
        height: 10vh;
        width: 10vw;
        gap: 0px;
    }
    .estilotitulo2 {
        width: 20vw;
        font-size: 1vh;
        height: 2vh;
    }
    .estilotitulo2-1 {
        width: 8vw;
        height: 2vh;
        font-size: 1vh;
        font-weight: bold;
        background-color: white;
        border-radius: 5px;
    }
    .estilotitulo3 {
        width: 15vw;
        height: 2vh;
        font-size: 1vh;
    }
}

.boxes3 {
    justify-content: center;
    align-items: center;
    width: 49vw;
    height: 15vh;
    display: flex;
    gap: 1%;
}

.box4 {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 10vh;
    width: 7vw;
    gap: 5px;
}

.box5 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7vh;
    width: 7vw;
    background-color: green;
    border-radius: 5px;
    font-size: 0.65vw;
    font-weight: bold;
    white-space: nowrap;
}


.estilotitulo4 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7vw;
    height: 3vh;
    font-size: 0.8vw;
    font-weight: bold;
    background-color: white;
    border-radius: 5px;
}

.selectgraph{
    height: 30px;
    width: 140px;
    border-radius: 100px;
}

.selectgraphbutons{
    height: 30px;
    border-Radius: 100px;
    text-Align: center;
}



.botoncabina{
    width: 80Px;
    height: 30Px;
    background-color: white;
    display: flex;
    align-items: center;
    place-content: center;
    border-radius: 30px;
    border: 2px solid black;
    cursor: pointer;
    font-size: 14px;
}

@media (max-width: 800px){
    .boxes {
        width: 98vw;
        height: 10vh;
    }
    .box1 {
        width: 20vw;
    }
    .box2 {
        width: 20vw;
        font-size: 1.4vh;
        height: 5vh;
    }
    .estilotitulo1 {
        width: 98vw;
        height: 2vh;
        font-size: 1.4vh;
    }
    .boxes3 {
        width: 98vw;
        height: 10vh;
    }
    .box4 {
        width: 20vw;
    }
    .box5 {
        height: 5vh;
        width: 14vw;
        font-size: 1.4vh;
    }
    .estilotitulo4 {
        width: 14vw;
        height: 2vh;
        font-size: 1vh;
    }
    .division3 {
        height: 55vh;
        top: -3%
    }
    .division3-0 {
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 3vh;
        display: flex;
        position: relative;
    }
    .selectgraph {
        height: 20px;
        width: 80px;
        font-size: 12px;
    }
    .selectgraphbutons{
        height: 20px;
        width: 80px;
        font-size: 12px;
        border: 2px solid black;
    }
    .botoncabina{
        width: 55Px;
        height: 20px;
        font-size: 12px;
    }
  }
.botoncabina:hover, .botoncabina:focus {
    box-shadow: 0 0 10px 1px #000000;
}

#menusidebar{
    position: absolute;
    left: 1%;
    top: 3%;
    cursor: pointer;
    z-index: 1;
    display: flex;
}
.division3 {
    max-height: 92vh; /* Ajusta la altura máxima de la división */
    overflow-y: auto; /* Habilita el scroll vertical cuando el contenido exceda la altura */
    padding: 10px; /* Espaciado interior, si es necesario */
    box-sizing: border-box; /* Asegura que el padding y los bordes se incluyan en la altura y anchura */
  }
  
.tablaResumen {
    margin: 20px 0;
    display: flex; /* Usamos flexbox para las columnas */
    justify-content: space-between; /* Para dar espacio entre las dos columnas */
    gap: 20px; /* Espacio entre las dos columnas */
}

.tablaResumen .columna {
    width: 48%; /* Asigna el 50% del espacio para cada columna */
    display: flex;
    flex-direction: column;
    gap: 10px; /* Espacio entre las filas dentro de cada columna */
}

.tablaResumen table {
    width: 100%;
    border: 1px solid #ddd;
}

.tablaResumen th, .tablaResumen td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
}

.tablaResumen th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.tablaResumen h3 {
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
}



.mosaicoResumen {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.mosaicoResumen h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    text-align: center;
}

.gridMosaicos {
    display: flex;
    flex-wrap: wrap; /* Permite que los elementos se envuelvan en nuevas líneas */
    gap: 20px; /* Espacio entre los elementos */
    justify-content: center; /* Centra los mosaicos dentro del contenedor */
    padding: 20px;
}

.mosaicoItem {
    background-color: #ffffff;
    border: 1px solid transparent;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    flex: 1 1 20%; /* Toma el 20% del ancho disponible y permite ajuste */
    min-width: 150px; /* Tamaño mínimo para evitar que los mosaicos se reduzcan demasiado */
    max-width: 200px; /* Tamaño máximo opcional */
}

.mosaicoItem:hover {
    transform: scale(1.05); /* Efecto de agrandamiento en hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Sombra más pronunciada en hover */
}

.mosaicoItem .equipoID,
.mosaicoItem .estatus {
    margin: 5px 0;
    font-size: 0.9em; /* Ajusta el tamaño de fuente para una mejor lectura */
    text-align: left; /* Alinea el texto a la izquierda */
}

.mosaicoItem .nombreEquipo {
    margin: 5px 0;
    font-size: 0.9em; /* Ajusta el tamaño de fuente para una mejor lectura */
}

/* Fondo verde para estatus "En Linea" */
.mosaicoItem.enLinea {
    background-color: #4caf50;
    border: 1px solid #388e3c; /* Borde verde para los mosaicos en linea */
}

/* Fondo azul para estatus que contiene "Última vez" */
.mosaicoItem.ultimaVez {
    background-color: #2196f3;
    border: 1px solid #1976d2; /* Borde azul para los mosaicos "Última vez" */
}

/* Fondo rojo para estatus "Sin datos en Base" */
.mosaicoItem.sinDatos {
    background-color: #f44336;
    border: 1px solid #d32f2f; /* Borde rojo para los mosaicos "Sin datos en Base" */
}

/* Especificamos el color de texto para los mosaicos */
.mosaicoItem.enLinea .equipoID,
.mosaicoItem.enLinea .nombreEquipo,
.mosaicoItem.enLinea .estatus {
    color: #fff; /* Blanco sobre verde */
}

.mosaicoItem.ultimaVez .equipoID,
.mosaicoItem.ultimaVez .nombreEquipo,
.mosaicoItem.ultimaVez .estatus {
    color: #fff; /* Blanco sobre azul */
}

.mosaicoItem.sinDatos .equipoID,
.mosaicoItem.sinDatos .nombreEquipo,
.mosaicoItem.sinDatos .estatus {
    color: #fff; /* Blanco sobre rojo */
}

.nombreEquipo {
    font-weight: bold;
    font-size: 1.1em;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.equipoID {
    color: #fff; /* Color blanco para los IDs en todos los casos */
}

.estatus {
    color: #fff;
    font-style: italic;
}
/* Estilos para el footer de leyenda */
.leyendaColores {
    display: flex;
    justify-content: flex-start; /* Alinea todo el contenido a la izquierda del footer */
    width: 100%; /* Asegura que el footer ocupe todo el ancho del contenedor */
    padding-left: 10px; /* Agrega un pequeño margen desde el borde izquierdo del contenedor */
    margin-top: 10px;
}

.leyendaLista {
    list-style-type: none; /* Sin viñetas */
    padding: 0;
    margin: 0;
    font-size: 0.9em; /* Tamaño de texto más pequeño */
}

.leyendaItem {
    margin-bottom: 5px; /* Espacio entre los elementos de la lista */
    font-weight: bold; /* Hace el texto más visible */
}

/* Color de texto para cada estado */
.leyendaItem.enLinea {
    color: #4caf50; /* Verde para "Con información en menos de 5 minutos" */
}

.leyendaItem.ultimaVez {
    color: #2196f3; /* Azul para "Sin datos por más de 5 minutos" */
}

.leyendaItem.sinDatos {
    color: #f44336; /* Rojo para "Sin registros en base de datos" */
}  
.boton-volver {
    background-color: #f39c12; /* Un color distintivo */
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    margin-right: 10px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .boton-volver:hover {
    background-color: #e67e22;
  }

  .icono-flecha {
    width: 20px; /* Ajusta el tamaño según tu preferencia */
    height: 20px;
    vertical-align: middle; /* Centra verticalmente en relación al botón */
  }

  .division3resumen {
    justify-content: center;
    align-items: center;
    width: 98vw;
    height: 80vh;
    display: grid;
    position: relative;
    border-radius: 10px;
    background-color: rgba(244, 222, 208, 0.9);
    outline: solid 2px #000000;
    backdrop-filter: blur(10px);
    padding: 10px 5px;
  }
  
/* Contenedor principal */
.resumenEquipos-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;  /* Reducido el espacio entre los elementos */
    padding: 8px;  /* Reducido el padding en todos los bordes */
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-width: 700px;  /* Reducido el ancho máximo */
    margin: 10px auto;  /* Reducido el margen en todos los bordes */
    flex-wrap: wrap;
  }
  
  /* Estilo para los inputs y el checkbox */
  .resumenEquipos-input {
    padding: 6px;  /* Reducido el padding */
    font-size: 13px;  /* Reducido el tamaño de la fuente */
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 130px;  /* Reducido el ancho */
    box-sizing: border-box;
  }
  
  .resumenEquipos-checkbox {
    margin-right: 8px;  /* Reducido el margen */
    transform: scale(1.1);
  }
  
  /* Contenedor de los botones, se usa flex para alinearlos horizontalmente */
  .resumenEquipos-buttons {
    display: flex;
    align-items: center;
    gap: 8px;  /* Reducido el espacio entre los botones */
  }
  
  /* Estilo para el botón de "Enviar" */
  .resumenEquipos-button {
    padding: 8px 16px;  /* Reducido el padding */
    font-size: 14px;  /* Reducido el tamaño de la fuente */
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .resumenEquipos-button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  
  .resumenEquipos-button:hover:not(:disabled) {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  /* Estilo para los mensajes de carga o descarga */
  .resumenEquipos-loading {
    font-size: 14px;  /* Reducido el tamaño de la fuente */
    color: #007bff;
    font-weight: bold;
    margin-left: 12px;  /* Reducido el margen */
  }
  
  /* Estilo para el botón de "Descargar CSV" */
  .resumenEquipos-download-button {
    padding: 8px 16px;  /* Reducido el padding */
    font-size: 14px;  /* Reducido el tamaño de la fuente */
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .resumenEquipos-download-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .resumenEquipos-download-button:hover:not(:disabled) {
    background-color: #218838;
    transform: scale(1.05);
  }
  
  /* Espaciado adicional si los elementos son demasiado grandes */
  .resumenEquipos-form {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
    justify-content: flex-start;
  }
  